@keyframes wind-blow {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes branch-sway {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes wave {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }
  25% {
    transform: perspective(1000px) rotateY(10deg) translateX(2px);
  }
  50% {
    transform: perspective(1000px) rotateY(0deg) translateX(-2px);
  }
  75% {
    transform: perspective(1000px) rotateY(-10deg) translateX(2px);
  }
  100% {
    transform: perspective(1000px) rotateY(0deg);
  }
}

.animate-wind-blow {
  animation: wave 4s ease-in-out infinite;
}

.animate-branch-sway {
  animation: branch-sway 6s ease-in-out infinite;
}
